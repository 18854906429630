import React from "react";
import TitleHeader from "../../components/TitleHeader";
import Layout from "../../components/layout"


const KompleksowaOpiekaStom = () => {
  return (
    <Layout>
      <TitleHeader title="Kompleksowa opieka stomatologiczna"/>
      
      <div className="grid-two-column content">
        <h2 className="first-column">w trosce o zdrowie</h2>
        <p className="second-column">
          Opieka premium. Zapewniamy najwyższy poziom opieki
          zapobiegawczo-leczniczej, staranną profilaktykę i bezbolesne leczenie.
          Regularne wizyty uchronią Cię przed koniecznością leczenia zębów,
          które odpowiednio wcześnie objęte ochroną stomatologiczną, dłużej
          utrzymają naturalną kondycję.
        </p>
        <h2 className="first-column">leczenie i profilaktyka</h2>
        <p className="second-column">
          Lekarze i specjaliści Dental Arts Studio Kraków z pasją wypełniają
          zobowiązanie, którym jest troska o Twoje zdrowie. Dzięki
          profesjonalnej stomatologii zachowawczej, możemy zapobiegać
          próchnicowej erozji szkliwa. Leczymy ubytki bezboleśnie. Zapewniamy
          najwyższy komfort zabiegów. Endodoncja. Osiągamy najwyższy poziom
          precyzji w leczeniu chorób miazgi zęba oraz tkanek
          okołowierzchołkowych. Elektroniczne narzędzia wspierają nas w
          pomiarach i w wykonywaniu zabiegów. Najnowocześniejszy sprzęt pozwala
          na maksymalizację efektów leczenia. konsultacje stomatologiczne
          profesjonalna higienizacja leczenie zachowawcze endodoncja
          mikroskopowa – leczenie kanałowe chirurgia – implantologia pantomogram
          – rtg zębów rtg punktowe tomografia stomatologiczna
        </p>
      </div>
    </Layout>
  );
};
export default KompleksowaOpiekaStom;
